import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SubscriptionComponent } from 'projects/aloe2-library/src/lib/components/common/subscription/subscription.component';
import { Store } from 'projects/aloe2-library/src/lib/store';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { AuthProvider } from '../common/auth.provider';
import { LogoutService } from '../common/logout.service';
import { TITLE_STATE } from '../data/models/common.model';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SubscriptionComponent implements OnInit {
  title = 'aloe2-main';

  currentProgress = 0;
  routeChangeSubject = new Subject<NavigationEnd>();
  inActivitySubject = new Subject();

  idleStarted = false;

  isTabActive = true;
  shouldLogout = false;
  last_login: any = 0;

  activeUrl: any = '/dasbhoard';

  loadingRouteConfig: boolean;
  router = inject(Router);

  currenrUrl: any;

  loadingRouteConfigSubject = new BehaviorSubject(true);
  loadingRouteConfig$ = this.loadingRouteConfigSubject.asObservable()
        .pipe(
          debounceTime(250),
          distinctUntilChanged()
        );


  constructor(
      private readonly idle: Idle,
      private readonly auth: AuthProvider,
      private readonly titleSvc: Title,
      private readonly store: Store,
      private readonly logoutSvc: LogoutService,
      private readonly keepalive: Keepalive) {
    super();

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setIdle(environment.idleSeconds); //   30 minutes
    // this.idle.setIdle(5 * 60);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    // this.idle.setTimeout(5 * 60)
    this.idle.setTimeout(environment.timeoutSeconds);// 90 * 60 // 90 minutes
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document

    this.idle.onIdleEnd.subscribe(() => {
      this.auth.updateTokenFromStorage()
        .then(() => {
          console.log('idle end');
          // check if refresh token will expire within next 30 minutes
          // if not create new refresh token
          this.auth.checkExpiredRefreshToken(5)
          .pipe(
            take(1)
          )
            .subscribe(expired => {
              if(expired) {
                console.log('call refresh token here.');
                this.auth.refreshToken();
              }
            });
        })
    });
    
    this.idle.onTimeout.subscribe(() => {
      
      // ignore public for timeout
      if(this.router.url.includes('/public') ||
      this.router.url.includes('account/login') ||
      this.router.url.includes('account/logout') || 
      this.router.url.includes('/support') || 
      this.router.url.includes('/activities') || 
      this.router.url.includes('/client')) {
        this.timeout();
        return;
      }
      
      this.logoutSvc.logout('timeout', this.router.url, 'app.component.timeout');
    });

    idle.onIdleStart.subscribe(() => {
      this.idleStarted = true;
    });
    

    this.router.events.subscribe((evt) => {
      if(evt instanceof NavigationEnd) {
        const evt_nav: NavigationEnd = evt as NavigationEnd;
        if(evt_nav.url === '/') {
          return;
        }

        this.routeChangeSubject.next(evt_nav);
      }
    });

    this.routeChangeSubject.pipe(
      debounceTime(1000),
    ).subscribe((evt: NavigationEnd) => {

      // ignore public for timeout
      if(evt.url.includes('/public') ||
         evt.url.includes('/account') || 
         evt.url.includes('/support') || 
         evt.url.includes('/widget') || 
         evt.url.includes('/upgrade') || 
         evt.url.includes('/support') || 
         evt.url.includes('/client')) {
        this.timeout();
        return;
      }

      if(!this.idleStarted) {
        this.reset();
        this.auth.checkExpiredRefreshToken()
              .toPromise()
              .then((expired) => {
                if(expired) {
                  console.log('tab active ', this.isTabActive);
                  if(this.isTabActive) {
                    this.logout('navigate url change');
                  } else {
                    this.shouldLogout = true;
                  }
                }
              })
      }
    });
    
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      console.log(`timeout countdown ${countdown}`)
    });
    

    // sets the ping interval to 30 minutes
    this.keepalive.interval(30 * 60);

    window.onfocus = () => {
      this.isTabActive = true;
      console.log('window focus ', this.isTabActive, 'shoudld logout ', this.shouldLogout, this.router.url);
      if(this.shouldLogout) {
        window.location.reload();
      } else {
        if(this.router.url.includes('/account/login')) {
          window.location.reload();
          return;
        }
        this.reset()
      }
    }

    window.onblur = () => {
      this.isTabActive = false;
      console.log('window unfocus ', this.isTabActive)
    }
  }

  timeout() {
    this.idleStarted = true;
    this.idle.stop();
  }

  logout(id: any) {
    this.shouldLogout = false;
    this.logoutSvc.logout(id, this.activeUrl, 'app.component logout');
  }

  reset() {
    this.shouldLogout = false;
    this.idleStarted = false;
    this.idle.watch();
  }

  ngOnInit(): void {
    this.auth.init();
    this.subscribe(this.store.select(TITLE_STATE).pipe(
      distinctUntilChanged(),
      debounceTime(500),
    ), (title: any) => {
      this.titleSvc.setTitle(title ? title : "ALOE");
    });


    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfigSubject.next(true);
      } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfigSubject.next(false);
      }
  });
  }
}
