import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  RouterModule,
  Routes
} from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { BatchAiGuard } from './modules/batch-ai/batch-ai.guard';
import { PublicTaskFormGuard } from './modules/public/task/guard/public-task-form.guard';
import { TaskAuthGuard } from './shared/guards/task-auth.guard';
import { ReportResolver } from './modules/report/common/report.resolver';
import { PublicRepositoryResolver } from './modules/public/repository/public-repository.module';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/overview',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'support',
    loadChildren: () => import('./shared/components/support/support.module').then((m) => m.SupportRouteModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [TaskAuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'categories',
    canActivate: [AuthGuard],
    data: { hasMobile: true },
    loadChildren: () =>
      import('./modules/category/category.module').then(
        (m) => m.CategoryModule
      ),
  },
  {
    path: 'activities',
    canActivate: [AuthGuard],
    data: { hasMobile: true },
    loadComponent: () => import('./modules/dashboard/containers/activity/activity.component').then((m) => m.ActivityComponent),
  },
  {
    path: 'task',
    canActivate: [AuthGuard],
    data: { hasMobile: true},
    loadChildren: () =>
      import('./modules/task/task.module').then((m) => m.TaskModule),
  },
  {
    path: 'send',
    canActivate: [TaskAuthGuard],
    data: {hasMobile:true},
    loadChildren: () =>
      import('./modules/send/send.module').then((m) => m.SendModule),
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    data: { hasMobile: true},
    loadChildren: () =>
      import('./modules/report/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'data-room',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/data-room/data-room.module').then(
        (m) => m.DataRoomModule
      ),
  },
  {
    path: 'batch-ai',
    canActivate: [AuthGuard, BatchAiGuard ],
    data: { hasMobile: false },
    loadChildren: () =>
      import('./modules/batch-ai/batch-ai.module').then(
        (m) => m.BatchAIModule
      ),
  },
  {
    path: 'repositories',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/repositories/repository.module').then(
        (m) => m.RepositoryModule
      ),
  },
  {
    path: 'calendar',
    canActivate: [TaskAuthGuard],
    data: { hasMobile: true},
    loadChildren: () =>
      import('./modules/calendar/calendar.module').then(
        (m) => m.CalendarModule
      ),
  },
  {
    path: 'widget/calendar',
    canActivate: [AuthGuard],
    data: { hasMobile: true },
    loadComponent: () => import('./modules/calendar/containers/widget/calendar.component').then((m) => m.CalendarComponent),
  },
  {
    path: 'public/task',
    canActivate: [PublicTaskFormGuard],
    loadChildren: () =>
      import('./modules/public/task/public-task.module').then(
        (m) => m.PublicTaskModule
      ),
  },
  {
    path: 'timeline',
    canActivate: [TaskAuthGuard],
    data: { hasMobile: true},
    loadChildren: () =>
      import('./modules/timeline/timeline.module').then(
        (m) => m.TimelineModule
      ),
  },
  {
    path: 'public/:type',
    loadChildren: () =>
      import('./modules/public/repository/public-repository.module').then(
        (m) => m.PublicRepositoryModule
      ),
  },
  // {
  //   path: 'public/:type',
    // resolve: {
    //   type: PublicRepositoryResolver
    // },
    // data: {
    //   type: 'data-room'
    // },
  //   loadChildren: () =>
  //     import('./modules/public/repository/public-repository.module').then(
  //       (m) => m.PublicRepositoryModule
  //     ),
  // },
  // {
  //   path: 'public/data-room',
  //   resolve: {
  //     type: PublicRepositoryResolver
  //   },
    
  //   data: {
  //     type: 'data-room'
  //   },
  //   loadChildren: () =>
  //     import('./modules/public/repository/public-repository.module').then(
  //       (m) => m.PublicRepositoryModule
  //     ),
  // },
  // {
  //   path: 'public/repositories',
  //   resolve: {
  //     type: PublicRepositoryResolver
  //   },
  //   data: {
  //     type: 'repositories'
  //   },
  //   loadChildren: () =>
  //     import('./modules/public/repository/public-repository.module').then(
  //       (m) => m.PublicRepositoryModule
  //     ),
  // },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./modules/notification/notification.module').then(
        (m) => m.NotificationModule
      ),
  },
  {
    path: 'access-logs',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/access-log/access-log.module').then(
        (m) => m.AccessLogModule
      ),
  },
  {
    path: 'app-library',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/app-library/app-library.module').then((m) => m.AppLibraryModule),
  },
  {
    path: 'shared/list',
    canActivate: [AuthGuard],
    loadComponent: () => import('./modules/report/components/share/share.component').then(m => m.ShareComponent),
        resolve: {
          taskDetail: ReportResolver,
        },
  },
  {
    path: 'share',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/share-page/share-page.module').then(
        (m) => m.SharePageModule
      ),
  },
  {
    path: 'client',
    loadChildren: () =>
      import('./modules/protected/protected.module').then(
        (m) => m.ProtectedModule
      ),
  },
  {
    path: 'quick-links',
    canActivate:[AuthGuard],
    data: { hasMobile: true},
    loadChildren: () =>
      import('./modules/quick-links/quick-links.module').then(
        (m) => m.QuickLinksModule
      ),
  },
  {
    path: 'not-available',
    loadChildren: () =>
      import('./modules/not-available/not-available.module').then(
        (m) => m.NotAvailableModule
      ),
  },
  {
    path: 'clauses',
    loadChildren: () =>
      import('./modules/clauses/clauses.module').then(
        (m) => m.ClausesModule
      ),
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./modules/templates/templates.module').then(
        (m) => m.TemplatesModule
      ),
  },
  {
    path: 'upgrade',
    loadComponent: () => import('./shared/components/upgrade/upgrade.component').then((m) => m.UpgradeComponent),
  },
  {
    path: '404',
    loadComponent: () => import('./shared/errors/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
  {
    path: 'blank',
    loadComponent: () => import('./shared/errors/blank/blank.component').then((m) => m.BlankComponent),
  },

];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 220],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
